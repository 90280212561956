/* eslint-disable camelcase */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const homePage = 'https://app.meetingsync.io';
export const apiHost = 'https://api.meetingsync.io/v1/';
export const apiRoot = 'https://api.meetingsync.io/';
// export const apiHost = "http://localhost:3038/v1/";
// export const apiRoot = "http://localhost:3038/";
export const logoUrl = `${apiRoot}appLogo/app_logo_emailverifier.jpg`;
export const adminRoles = ['admin', 'owner'];
export const systemRole = 'superuser';
export const userRole = 'user';
export const ownerRoles = ['superuser', 'owner'];
export const allTypeManager = [...adminRoles, systemRole];
export const customerCheckerApi = createApi({
    reducerPath: 'customerCheckerApi',
    baseQuery: fetchBaseQuery({
        baseUrl: apiHost,
        prepareHeaders: headers => headers,
    }),
    tagTypes: ['jobLogs', 'userPhoto', 'userProfile', 'userList', 'packageList', 'workspaceList', 'connectionsList'],
    endpoints: builder => ({
        registerUser: builder.mutation({
            query: inputData => ({
                method: 'POST',
                url: 'auth/register',
                body: inputData,
            }),
        }),
        registerOwnerUser: builder.mutation({
            query: inputData => ({
                method: 'POST',
                url: 'auth/register',
                body: inputData,
            }),
        }),
        loginUser: builder.mutation({
            query: inputData => ({
                method: 'POST',
                url: 'auth/login',
                body: inputData,
            }),
        }),
        saveAuthGoogle: builder.mutation({
            query: ({token, inputData}) => ({
                method: 'POST',
                url: 'auth/authcallback',
                body: inputData,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        }),
        saveAuthZoom: builder.mutation({
            query: ({token, inputData}) => ({
                method: 'POST',
                url: 'auth/authcallback-zoom',
                body: inputData,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        }),
        updateUser: builder.mutation({
            query: ({ user_id, token, formData }) => ({
                method: 'PATCH',
                url: `users/${user_id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
            invalidatesTags: ['userList']
        }),
        upgradeUser: builder.mutation({
            query: ({ user_id, token }) => ({
                method: 'PATCH',
                url: `users/${user_id}/upgrade`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: ['userList']
        }),
        degradeUser: builder.mutation({
            query: ({ user_id, token }) => ({
                method: 'PATCH',
                url: `users/${user_id}/degrade`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: ['userList']
        }),
        deleteUser: builder.mutation({
            query: ({ user_id, token }) => ({
                method: 'DELETE',
                url: `users/${user_id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }),
            invalidatesTags: ['userList']
        }),
        getUsers: builder.query({
            query: ({ token, query }) => ({
                method: 'GET',
                url: `users/${query}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }),
            providesTags: ['userList']
        }),
        ResetRequest: builder.mutation({
            query: (body) => ({
                    method: 'POST',
                    url: `auth/reset`,
                    body,
                }),
        }),
        CompleteReset: builder.mutation({
            query: (body) => ({
                    method: 'PUT',
                    url: `auth/reset`,
                    body,
                }),
        }),
        VerifyToken: builder.mutation({
            query: (body) => ({
                    method: 'PUT',
                    url: `auth/verify/${body}`,
                }),
        }),
        getUserMetaByKey: builder.query({
            query: ({ token, meta_key }) => ({
                method: 'GET',
                url: `usermeta?meta_key=${meta_key}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 0
        }),
        getUserMetaByAdmin: builder.query({
            query: ({ token, meta_key }) => ({
                method: 'GET',
                url: `system/meta?meta_key=${meta_key}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 0
        }),
        getAllServiceTokens: builder.query({
            query: (token) => ({
                method: 'GET',
                url: `system/servicetokens`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 0,
            providesTags: ['connectionsList'],
        }),
        getUserJobs: builder.query({
            query: (token) => ({
                method: 'GET',
                url: `job_logs`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            keepUnusedDataFor: 0,
            providesTags: ['jobLogs']
        }),
        getJobStats: builder.query({
            query: (token) => ({
                method: 'GET',
                url: `job_logs/stats`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            // keepUnusedDataFor: 30,
        }),
        getUserProfilePhoto: builder.query({
            query: (token, id) => ({
                method: 'GET',
                url: `users/${id}/photo`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            // keepUnusedDataFor: 30,
            providesTags: ['userPhoto']
        }),
        getGdriveFolders: builder.query({
            query: (token) => ({
                method: 'GET',
                url: `system/gdrivefolders`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            // keepUnusedDataFor: 30,
            // providesTags: ['userPhoto']
        }),
        updateGdriveFolder: builder.mutation({
            query: ({token, formdata }) => ({
                method: 'PATCH',
                url: `system/gdrivefolders`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formdata,
            }),
            // keepUnusedDataFor: 30,
            // providesTags: ['userPhoto']
        }),
        getStripePubKey: builder.query({
            query: () => ({
                method: 'GET',
                url: `system/stripe`,
            }),
            keepUnusedDataFor: 0
        }),
        getStripePubKeyOwner: builder.query({
            query: (workspace) => ({
                method: 'GET',
                url: `system/stripe_owner?ws=${workspace}`,
            }),
            keepUnusedDataFor: 0
        }),
        updateUserProfilePhoto: builder.mutation({
            query: ({token, formData }) => ({
                method: 'PUT',
                url: `users/${formData.userId}/photo`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
            invalidatesTags: ['userPhoto']
        }),
        updateUserProfile: builder.mutation({
            query: ({ token, formData, userId }) => ({
                method: 'PUT',
                url: `users/${userId}/user`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
        }),
        updateUserJob: builder.mutation({
            query: ({token, formData }) => ({
                method: 'PUT',
                url: `job_logs`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
            invalidatesTags: ['jobLogs']
        }),
        updateUserMeta: builder.mutation({
            query: ({ meta_id, token, formData }) => ({
                method: 'PUT',
                url: `usermeta/${meta_id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
        }),
        createUserMeta: builder.mutation({
            query: ({ token, formData }) => ({
                method: 'POST',
                url: `usermeta`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
        }),
        getCreditPrice: builder.query({
            query: ({ token, key }) => ({
                method: 'GET',
                url: `usermeta/manage?key=${key}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            invalidatesTags: ['userList']
        }),
        updateUserMetaAdmin: builder.mutation({
            query: ({ token, formData }) => ({
                method: 'PUT',
                url: `usermeta/manage`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
            invalidatesTags: ['packageList', 'userPhoto', 'userList']

        }),
        getWorkspaces: builder.query({
            query: (token) => ({
                method: 'GET',
                url: `workspace/manage`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            providesTags: ['workspaceList']
        }),
        getWorkspaceDetail: builder.query({
            query: (ws) => ({
                method: 'GET',
                url: `workspace/detail?ws=${ws}`,
            }),
        }),
        updateWorkspaceSystem: builder.mutation({
            query: ({ token, formData }) => ({
                method: 'PUT',
                url: `workspace/manage`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
            invalidatesTags: ['workspaceList']

        }),
        removeWorkspace: builder.mutation({
            query: ({ token, formData }) => ({
                method: 'DELETE',
                url: `workspace/manage`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
            invalidatesTags: ['workspaceList']
        }),
        updateAppLogo: builder.mutation({
            query: ({ token, formData }) => ({
                method: 'POST',
                url: `system/logo`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
        }),
        updateStripeCredentials: builder.mutation({
            query: ({ token, formData }) => ({
                method: 'PUT',
                url: `system/appInfo`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
        }),
        UploadStripeCustomers: builder.mutation({
            query: ({ token, formData }) => ({
                method: 'POST',
                url: `shorts_job`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
            invalidatesTags: ['jobLogs']
        }),
        makePayment: builder.mutation({
            query: ({ token, formData }) => ({
                method: 'POST',
                url: `payment`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
        }),
        makePaymentConfirm: builder.mutation({
            query: ({ token, formData }) => ({
                method: 'POST',
                url: `payment/confirm`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
        }),
        generatePaymentManagementSession: builder.mutation({
            query: ({ token, formData }) => ({
                method: 'POST',
                url: `payment/generate_stripe_session`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
        }),
        createWorkspace: builder.mutation({
            query: ({ token, formData }) => ({
                method: 'POST',
                url: `workspace`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
        }),
        paymentTransactions: builder.query({
            query: (token) => ({
                method: 'GET',
                url: `payment/transactions`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            // keepUnusedDataFor: 30,
        }),
        paymentTransactionsAdmin: builder.query({
            query: (token) => ({
                method: 'GET',
                url: `system/payments`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            // keepUnusedDataFor: 30,
        }),
        // special endpoint 
        dashboardStatsAdmin: builder.query({
            query: ({workspace = '', token}) => ({
                method: 'GET',
                url: `system/stats${workspace}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            // keepUnusedDataFor: 30,
        }),
        getCreditPacks: builder.query({
            query: (token) => ({
                method: 'GET',
                url: `system/creditpack`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            // keepUnusedDataFor: 30,
            providesTags: ['packageList']
        }),
        addCreditPack: builder.mutation({
            query: ({ token, formData }) => ({
                method: 'POST',
                url: `system/creditpack`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
            invalidatesTags: ['packageList']
        }),
        updateCreditPack: builder.mutation({
            query: ({ token, formData }) => ({
                method: 'PATCH',
                url: `system/creditpack`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
            invalidatesTags: ['packageList']
        }),
        removeCreditPack: builder.mutation({
            query: ({ token, formData }) => ({
                method: 'DELETE',
                url: `system/creditpack`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
            invalidatesTags: ['packageList']
        }),
        sendResetPwRequest: builder.mutation({
            query: (formData) => ({
                method: 'POST',
                url: `auth/forgot-password`,
                body: formData,
            }),
        }),
        confirmResetPw: builder.mutation({
            query: ({formData, token}) => ({
                method: 'POST',
                url: `auth/reset-password?token=${token}`,
                body: formData,
            }),
        }),
        unlinkServiceConnection: builder.mutation({
            query: ({ token, formData }) => ({
                method: 'PATCH',
                url: `system/servicetokens`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
            invalidatesTags: ['connectionsList'],
        }),
    }),
});

export const {
    useResetRequestMutation,
    useCompleteResetMutation,
    useVerifyTokenMutation,
    useLoginUserMutation,
    useUpdateUserMutation,
    useRegisterUserMutation,
    useGetUserMetaByKeyQuery,
    useUpdateUserMetaMutation,
    useUploadStripeCustomersMutation,
    useGetUserJobsQuery,
    useUpdateUserJobMutation,
    useMakePaymentMutation,
    useMakePaymentConfirmMutation,
    useGetJobStatsQuery,
    useGetUserProfilePhotoQuery,
    useUpdateUserProfilePhotoMutation,
    useUpdateUserProfileMutation,
    useSendResetPwRequestMutation,
    useConfirmResetPwMutation,
    usePaymentTransactionsQuery,
    useGetUsersQuery,
    useDeleteUserMutation,
    useUpdateUserMetaAdminMutation,
    useUpdateAppLogoMutation,
    useUpdateStripeCredentialsMutation,
    useGetStripePubKeyQuery,
    usePaymentTransactionsAdminQuery,
    useDashboardStatsAdminQuery,
    useUpgradeUserMutation,
    useDegradeUserMutation,
    useCreateUserMetaMutation,
    useGetCreditPacksQuery,
    useAddCreditPackMutation,
    useUpdateCreditPackMutation,
    useRemoveCreditPackMutation,
    useGetCreditPriceQuery,
    useGetWorkspacesQuery,
    useUpdateWorkspaceSystemMutation,
    useRemoveWorkspaceMutation,
    useGetUserMetaByAdminQuery,
    useCreateWorkspaceMutation,
    useGetWorkspaceDetailQuery,
    useGetStripePubKeyOwnerQuery,
    useSaveAuthGoogleMutation,
    useSaveAuthZoomMutation,
    useGetAllServiceTokensQuery,
    useUnlinkServiceConnectionMutation,
    useGetGdriveFoldersQuery,
    useUpdateGdriveFolderMutation,
    useGeneratePaymentManagementSessionMutation,
} = customerCheckerApi;
